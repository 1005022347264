import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cognitouser:'test@ryomo.co.jp',
    cognitopass:'testtest',
    loginuser: {
      loginchrgdepcd: "", 
      loginchrgdepnm: "", 
      loginusercd: "", 
      loginusernm: "", 
      loginathryclsfy: ""
    }
  },
  mutations: {
    loginUserEntry(state,list){
      state.loginuser.loginchrgdepcd = list[0].loginchrgdepcd
      state.loginuser.loginchrgdepnm = list[0].loginchrgdepnm
      state.loginuser.loginusercd = list[0].loginusercd
      state.loginuser.loginusernm = list[0].loginusernm
      state.loginuser.loginathryclsfy = list[0].loginathryclsfy
    }
    
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
