import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { ITextValue } from "@/types/ITextValue";
export default class ControlBaseVue extends Vue {
  @Prop() public label!: string;
  @Prop() public disabled!: boolean;
  @Prop({ type: Boolean, default: false }) public required!: boolean;
  @Prop() public readonly!: boolean;
  @Prop({ type: String, default: "" }) public value!: string;
  @Prop() public text!: string;
  @Prop() public items!: ITextValue[];
 
}
  
