















import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import ControlBaseVue from "@/components/controls/base/ControlBaseVue";

@Component
export default class TextArea extends ControlBaseVue {
  // tslint:disable-next-line:typedef
  @Emit() 
  input(value: string) {
    console.log(value)}

  private get localValue(): string {
    return this.value;
  }

  private set localValue(value: string) {
    this.input(value);
  } 
  
  backgroundcolor: string = this.color(this.required);
  ret!: string;
  color(required: boolean): string{

    if (required === true ) {

      return this.ret = "#FFF59D";
    }
    else{
      return this.ret = "#FFFFFF";
    }
  }
}
