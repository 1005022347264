












































































































































































import Vue from 'vue';
import { API, Auth } from 'aws-amplify';
import { Component } from 'vue-property-decorator';
import { IHeader } from "@/types/IHeader";
import { IHomeParams } from "@/types/IHomeParams";
import { IHomeSelectList } from "@/types/IHomeSelectList";
import HomeEdit from "@/components/HomeEdit.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";
import CustomLoading from "@/components/CustomLoading.vue";

@Component({
  components: {
    HomeEdit,
    ConfirmEx,
    CustomLoading
  }
})

export default class Home extends Vue {

  // ダイアログ制御
  dialog = false;
  title!: string;
  color!: string;
  mainRequired = true;
  tabNum = 0;
  
  // 処理待機中描画
  isLoading = false;
  
  // タブ一覧の選択行番号の初期位置
  editIndex = -1;
  
  // メッセージNo桁
  length = 10;
  
  /**
   * refsの定義
   */
  get refs(): any {
    return this.$refs;
  }
  
  /**
   * 各データテーブル ヘッダー定義
   */
  // タブ
  tabs: any = null;
  items: any = [
    { tab: "受信", content: "Tab 1 Content" },
    { tab: "送信", content: "Tab 2 Content" },
  ];
  // メッセージ情報
  headersMessage: IHeader[] = [
      { text: "開く", value: "actions", sortable: false },
      { text: "No", sortable: false, value: "totlCnsnb" },
      { text: "件名", sortable: false, value: "mssgTitle" },
      { text: "宛先", sortable: false, value: "trnsmAhdNm" },
      { text: "送信者", sortable: false, value: "trnsmCuzNm" },
      { text: "送信日", sortable: false, value: "trnsmYmdtJP" },
      { text: "状態", sortable: false, value: "urdFlgVal" },
    ];
    
  // 下記、(詳細)用ヘッダー定義
  syousaiHeaders = [
      { text: "年度", sortable: false, value: "tyr"},
      { text: "管理番号", sortable: false, value: "admstnb" },
      { text: "要件区分", sortable: false, value: "cndtcd" },
      { text: "受付年月日", sortable: false, value: "accptymdt" },
      { text: "受付番号", sortable: false, value: "accptnb" },
      { text: "受付場所", sortable: false, value: "accptplcd" },
      { text: "行政・団体", sortable: false, value: "admar" },
      { text: "代表者", sortable: false, value: "rprsnone" },
      { text: "肩書", sortable: false, value: "pstnm" },
      { text: "件名", sortable: false, value: "pttntitle" },
  ];
  // 新着(詳細)
  headersSintyaku: IHeader[] = this.syousaiHeaders;
  // 振り分け未実施(詳細)
  headersMifuriwake: IHeader[] = this.syousaiHeaders;
  // 対応中(詳細)
  headersTaioutyu: IHeader[] = this.syousaiHeaders;
  
  selectList: IHomeSelectList = {
    tantoukaList: [],
  };

  /**
   * 各データテーブル アイテム定義
   */
  itemsMessage: IHomeParams[] = [];
  itemsSintyaku = [];
  itemsMifuriwake = [];
  itemsTaioutyu = [];
  
  // 修正前メッセージ格納
  keepSelectData: IHomeParams = {};
  // 修正後メッセージ格納
  editData: IHomeParams = {};
  // 当日日付和暦（新規登録）
  getDateJP!: IHomeParams[];
  countMessage!: IHomeParams[];
  
  // 画面ロード
  async created() {
    
    if(!(this.$store.state.loginuser.loginusercd.length > 0)){   
      await alert("ログイン情報が取得できません。再度ログインを実施してください。");
      window.location.assign(process.env.BASE_URL||"");
    }
    
    // 初期設定
    await this.info();

  }
  
  async info() {
    this.isLoading = true;

    await this.getParam();
    await this.getMessage(this.tabNum);
    await this.getInfoArea();

    this.isLoading = false;
  }
  
  async tabDataLoding(tab: number) {
    if (tab === 0) {
      this.tabNum = 0;
      await this.getMessage(this.tabNum);
    }
    else if(tab === 1){
      this.tabNum = 1;
      await this.getMessage(this.tabNum);
    }
    
  }
  
  
  /**
   * パラメータ情報取得
   */
  async getParam(){
    
    try {
      
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
      
      // 担当paramsセット
      apiparams.body.manipulate = "SelectHomePara";
      apiparams.body.sqlparam = this.$store.state.loginuser;
      this.selectList = await API.post("asmapigateway", "/asmHome", apiparams);
      console.log(this.selectList);

    } catch (err) {
      console.log(err);
      return;
    }
    
  }
  
  /**
   * メッセージ情報取得
   */
  async getMessage(tabNum: number) {

    try {
      
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
      
      // message取得API
      if(tabNum === 0){
        apiparams.body.manipulate = "GETMESSAGE";
      }
      else if(tabNum === 1){
        apiparams.body.manipulate = "SENDMESSAGE";
      }
      
      apiparams.body.sqlparam = this.$store.state.loginuser;
      this.itemsMessage = await API.post("asmapigateway", "/asmHome", apiparams);
      
    } catch (err) {
      console.log(err);
      return;
    }
  }
  
  /**
   * 新着・振り分け未実施・対応中髭右徳
   */
  async getInfoArea() {

    try {
      
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {},
          dayparam: ""
        }
      };
      
      // paramセット
      apiparams.body.sqlparam = this.$store.state.loginuser;
      const getDay = await this.getDate();
      apiparams.body.dayparam = getDay.day;
      console.log(apiparams.body.dayparam);
      
      // 新着エリア取得API
      apiparams.body.manipulate = "SELECTSINTYAKU";
      this.itemsSintyaku = await API.post("asmapigateway", "/asmHome", apiparams);
      console.log(this.itemsSintyaku);
      
      // 未振り分けエリア取得API
      apiparams.body.manipulate = "SELECTFURIWAKE";
      this.itemsMifuriwake = await API.post("asmapigateway", "/asmHome", apiparams);
      console.log(this.itemsMifuriwake);
      
      // 対応中エリア取得API
      apiparams.body.manipulate = "SELECTTAIOUTYU";
      this.itemsTaioutyu = await API.post("asmapigateway", "/asmHome", apiparams);
      console.log(this.itemsTaioutyu);
      
    } catch (err) {
      console.log(err);
      return;
    }

  }
  
  
  async createMesssage(){
  
    try {
      
      const apiparams = {
        body: {
          manipulate: "",
          dayparam: ""
        }
      };
      
      apiparams.body.manipulate = "GETMESSAGECOUNT";
      this.countMessage = await API.post("asmapigateway", "/asmHome", apiparams);
      
      apiparams.body.manipulate = "GETDATEJP";
      const getDay = await this.getDate();
      apiparams.body.dayparam = getDay.day;
      
      this.getDateJP = await API.post("asmapigateway", "/asmHome", apiparams);
  
    } catch (err) {
      console.log(err);
      return;
    }
    
    console.log(this.getDateJP);
    console.log("JP:" + this.getDateJP[0].trnsmYmdtJP);
    
    // データ格納オブジェクトの初期化
    this.$nextTick(() => {
      this.editIndex = -1;
      
      if(this.countMessage.length < 1){
        
        // 初期
        this.editData = {
          totlCnsnb: "0000000001",
          trnsmYmdtJP: this.getDateJP[0].trnsmYmdtJP,
          trnsmCuzCd: this.$store.state.loginuser.loginchrgdepcd,
          trnsmCuzNm: this.$store.state.loginuser.loginchrgdepnm
        };
      }
      else{
        
        // No作成
        const maxlength = this.countMessage.length - 1;
        console.log("maxlength:" + maxlength);
        
        let maxtotlCnsnb = String(Number(this.countMessage[maxlength].totlCnsnb) + 1);
        console.log("maxtotlCnsnb:" + maxtotlCnsnb);
        
        while (maxtotlCnsnb.length < this.length) {
          maxtotlCnsnb = "0" + maxtotlCnsnb;
        }

        this.editData = {
          totlCnsnb: maxtotlCnsnb,
          trnsmYmdtJP: this.getDateJP[0].trnsmYmdtJP,
          trnsmCuzCd: this.$store.state.loginuser.loginchrgdepcd,
          trnsmCuzNm: this.$store.state.loginuser.loginchrgdepnm
        };
      }
      
    });
    
    // ダイアログ制御
    this.title = "メッセージ登録";
    this.color = "pink";
    console.log(this.editData);
    this.mainRequired = true;
    this.dialog = true;
  }
  
  editMessage(selectMessage: IHomeParams){
    
    // ダイアログ制御
    this.title = "メッセージ表示";
    this.color = "light-blue";
    this.mainRequired = false;
    
    console.log("tabnum：" + this.tabNum);
    
    // 修正前メッセージ格納
    this.keepSelectData = Object.assign({}, selectMessage);
    
    console.log(selectMessage);
    this.editIndex = this.itemsMessage.indexOf(selectMessage);
    this.editData = Object.assign({}, selectMessage);
    
    console.log(this.editData);
    this.dialog = true;
  }
  
  /**
   * メッセージ登録
   */
  async saveMessage(returnMessage: IHomeParams) {
    
    console.log(returnMessage);
    // 入力チェック
    if (!returnMessage.totlCnsnb || !returnMessage.mssgTitle || !returnMessage.trnsmAhdCd || !returnMessage.trnsmCuzCd || !returnMessage.trnsmYmdtJP|| !returnMessage.mssgCont ) {
      await this.refs.confirm.open(
                "エラー",
                "すべての項目を入力してください。", 
                {
                type: "alert",
                color: "red",
                width: 500
                }
      );
      return ;
    }
    
    // 登録確認メッセージ
    if (
        !(await this.refs.confirm.open("確認", "登録します。よろしいですか？", {
          width: 500,
        }))
    ) {
      return;
    }
    this.cancelDialog();
    this.isLoading = true;
    
    if(this.color === "pink"){
      
      //送信者セット
      await this.setDefault(returnMessage);
    }
    
    // 送信時間etcセット
    const getDay = await this.getDate();
    returnMessage.trnsmYmdt = getDay.day;
    returnMessage.trnsmHms = getDay.time;
    
    // 削除処理(変更時)
    if (this.color === "light-blue"){
      
      // 削除API
      await this.deleteMessage(this.keepSelectData, "save");
      
      // 編集前の日付を再セット
      returnMessage.trnsmYmdt = this.keepSelectData.trnsmYmdt
      returnMessage.trnsmHms = this.keepSelectData.trnsmHms;
    }
    
    console.log(returnMessage);
    
    // insertAPI
    try {
      
      // 登録処理実行(新規登録)
      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
      console.log(returnMessage);
      apiparams.body.manipulate = "INSERTMESSAGE";
      apiparams.body.sqlparam = returnMessage;
      
      // insertAPI
      await API.post("asmapigateway", "/asmHome", apiparams);
      
      
    }catch (err) {
      this.isLoading = false;
      console.log("errInsertDbData+++++++++++++++");
      console.log(err);
    }
    
    this.isLoading = false;
    // 登録完了
    await this.refs.confirm.open(
      "完了",
      "登録が完了しました。", 
      {
        type: "alert",
        width: 500
      }
    );
    await this.info();
     
   }
   
  /**
   * 削除登録
   */
  async deleteMessage(returnMessage: IHomeParams, clickBtn: string) {
    
    if(this.color === "light-blue" && clickBtn === "delete"){
      // 削除確認(削除ボタンイベント時)
      if (
          !(await this.refs.confirm.open("確認", "削除します。よろしいですか？", {
            width: 500,
          }))
      ) {
        return;
      }
      this.cancelDialog();
      this.isLoading = true;
    }
    
    //削除API
    try {

      const apiparams = {
        body: {
          manipulate: "",
          sqlparam: {}
        }
      };
      
      apiparams.body.manipulate = "DELETEMESSAGE";
      apiparams.body.sqlparam = returnMessage;
      await API.post("asmapigateway", "/asmHome", apiparams);
      
    }catch (err) {
      this.isLoading = false;
      console.log("errInsertDbData+++++++++++++++");
      console.log(err);
    }

    
    if(this.color === "light-blue" && clickBtn === "delete"){
      this.isLoading = false;
      
      // 削除完了
      await this.refs.confirm.open(
        "完了",
        "削除が完了しました。", 
        {
          type: "alert",
          width: 500
        }
      );
      await this.info();
    }
    
  }
  
  /**
   * 各ダイアログのキャンセルイベント
   */
  cancelDialog() {
    
    // データ格納オブジェクトの初期化
    this.$nextTick(() => {
      this.editIndex = -1;
      this.editData = {};
    });
  
    // タブを閉じる
    this.dialog = false;
  }
  
  // 当日日付取得
  async getDate(){
    
    // const dateNow = new Date((Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000)));
    
    // sysdate取得
    const dateNow = new Date(Date.now());
    console.log("newDate：" + dateNow);

    // 年月日取得
    const monthMM = ('0' + (dateNow.getMonth() + 1)).slice(-2);
    const dayDD = ('0' + (dateNow.getDate())).slice(-2);
    const today =  dateNow.getFullYear().toString() + monthMM + dayDD;
    
    // 時間取得
    const hours = ('0' + (dateNow.getHours().toString())).slice(-2);
    const minutes = ('0' + (dateNow.getMinutes().toString())).slice(-2);
    const seconds = ('0' + (dateNow.getSeconds().toString())).slice(-2);
    const currentTime = hours + minutes + seconds;
    
    const dayTime = {day:today , time:currentTime};
    
    return dayTime;
  }
  
  async setDefault(returnMessage: IHomeParams){
    
    returnMessage.trnsmCuzCd = this.$store.state.loginuser.loginchrgdepcd;
    
    return returnMessage;
  }

}
