






















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ConfirmEx extends Vue {
  dialog = false;
  caption = "caption";
  message = "message";
  options: any = {
    type: "confirm",
    color: "indigo",
    width: 400
  };
  resolve: any;

  get visibleCancel(): boolean {
    return !(
      this.options !== undefined &&
      this.options.type !== undefined &&
      this.options.type === "alert"
    );
  }

  get titlebarColor(): string {
    return this.options === undefined || this.options.color === undefined
      ? "indigo"
      : this.options.color;
  }

  get width(): number {
    return this.options === undefined || this.options.width === undefined
      ? 400
      : this.options.width;
  }

  public open(
    caption: string,
    message: string,
    options: string
  ): Promise<boolean > {
    this.dialog = true;
    this.caption = caption;
    this.message = message;
    this.options = options;
    return new Promise<boolean>(resolve => {
      this.resolve = resolve;
    });
  }
  ok() {
    this.resolve(true);
    this.dialog = false;
  }
  cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
