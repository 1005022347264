










































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import store from '@/store'
import { Auth } from 'aws-amplify';
// import router from 'vue-router'

@Component({
  components: {
  },
})
export default class Login extends Vue {
  dialog = false;
  // drawer = null;
  drawer = false;
  loginUser = "";
  userdialog = false;
  loginchrgdepnm = "";
  loginusernm = "";
  
  
  
  items_admn = [
    { icon: 'mdi-home', text: 'Home', link:'/home' },
    { icon: 'mdi-desktop-mac', text: '情報管理', link:'/mainpage' },
    { icon: 'mdi-map-marker-radius', text: '位置情報', link:'/mapall' },
    { icon: 'mdi-printer', text: '帳票出力', link:'/outputform' },
    { icon: 'mdi-palette', text: 'EUC', link:'/outputdata' },
    { icon: 'mdi-cog', text: 'パラメータ管理', link:'/manegmentparam' },
    // { icon: 'mdi-account', text: 'ログイン／ログアウト', link:'/' },
  ]
  
  items = [
    { icon: 'mdi-home', text: 'Home', link:'/home' },
    { icon: 'mdi-desktop-mac', text: '情報管理', link:'/mainpage' },
    { icon: 'mdi-map-marker-radius', text: '位置情報', link:'/mapall' },
    { icon: 'mdi-printer', text: '帳票出力', link:'/outputform' },
    { icon: 'mdi-palette', text: 'EUC', link:'/outputdata' },
    // { icon: 'mdi-cog', text: 'パラメータ管理', link:'/manegmentparam' },
    // { icon: 'mdi-account', text: 'ログイン／ログアウト', link:'/' },
  ]
  loginUserInfo = [];
  info = [
          {
            loginchrgdepcd: "", 
            loginchrgdepnm: "", 
            loginusercd: "", 
            loginusernm: "", 
            loginathryclsfy: ""
          }
        ];
        
  async created() {
    // this.items = this.items_othr;
  }
  
  async logout(){
    this.userclose();
    store.commit('loginUserEntry', this.info);
    await Auth.signOut();
    window.location.assign(process.env.BASE_URL||"");
  }
  
  async userclose(){
     this.userdialog = false;
  }
  
  async loginuserinfo() {
    this.loginUser = this.$store.state.loginuser.loginchrgdepnm;
    // this.loginUserInfo = this.$store.state.loginuser;
    if(this.loginUser != ""){
      this.loginchrgdepnm = this.$store.state.loginuser.loginchrgdepnm;
      this.loginusernm = this.$store.state.loginuser.loginusernm;
      this.userdialog = true;
    } else {
      window.location.assign(process.env.BASE_URL||"");
    }
  }
    
  async openandclose(){
    const clsfy = this.$store.state.loginuser.loginathryclsfy;
    if(clsfy === '0'){
      this.items = this.items_admn;
    }
    this.drawer = !this.drawer;

  }
  
  async beforeDestroy(){
    store.commit('loginUserEntry', this.info);
    await Auth.signOut();
  }
  
  // async geotest(){
  //   // if(process.client){
  //     if(!navigator.geolocation){
  //       alert("取得できませんでした");
  //       return;
  //     }
      
  //     const options =  {
  //       enableHighAccuracy: false,
  //       timeout: 5000,
  //       maximumAge: 0
  //     }
      
  //     navigator.geolocation.getCurrentPosition(this.success,this.error,options);
  //   // }
  // }
  
  // async success(options: any){
  //   alert("緯度:"+options.coords.latitude+",経度"+options.coords.longitude);
  // }
  
  // async error(){
  //   alert("えらー");
  // }
  
  
}
