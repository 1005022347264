/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:53b82676-cc20-4a39-b018-5217ee1d0ce6",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_IYq8QFxOP",
    "aws_user_pools_web_client_id": "50471m5ctksnu11dhvokie1pp4",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ltwz3denhfedrgoywgy7sta4qy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "asmapigateway",
            "endpoint": "https://akj45x6a2d.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "asmimage82558-master",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "asm-hosting-master",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d2lz2tvp62jka9.cloudfront.net"
};


export default awsmobile;
