import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/mainpage',
    name: 'MainPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MainPage.vue')
  },
  {
    path: '/mapall',
    name: 'MapAll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MapAll.vue')
  },
  {
    path: '/outputform',
    name: 'OutputForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OutputForm.vue')
  },
  {
    path: '/outputdata',
    name: 'OutputData',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OutputData.vue')
  },
  {
    path: '/manegmentparam',
    name: 'ManegmentParam',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManegmentParam.vue')
  },
  {
    path: '/',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
      path: "/401",
      name: "unauthorized",
      component: () => import("@/views/Unauthorized.vue")
    },
    {
      path: "/403",
      name: "forbidden",
      component: () => import("@/views/Forbidden.vue")
    },
    {
      path: "/500",
      name: "internal-server-error",
      component: () => import("@/views/InternalServerError.vue")
    },
    {
      path: "*",
      name: "*",
      component: () => import("@/views/NotFound.vue")
    }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router