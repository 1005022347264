























































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IHomeParams } from "@/types/IHomeParams";
import { IHomeSelectList } from "@/types/IHomeSelectList";
import StringBox from "@/components/controls/StringBox.vue";
import TextArea from "@/components/controls/TextArea.vue";
import DropDownList from "@/components/controls/DropDownList.vue";
import ConfirmEx from "@/components/ConfirmEx.vue";

@Component({
  components: {
    StringBox,
    TextArea,
    DropDownList,
    ConfirmEx
  },
})

export default class HomeEdit extends Vue {
// パラメータ
  @Prop() messageParam!: IHomeParams;
  @Prop() selectList!: IHomeSelectList;
  @Prop() mainRequired!: boolean;
  @Prop() title!: string;
  @Prop() color!: string;
  @Prop() tabNum!: number;
  
  // 初期活性状態
  urdFlgdisable = false;
  dltnFlgdisable = false;
  Noreadonly = false;
  titlereadonly = false;
  ahdreadonly = false;
  cuzreadonly = false;
  ymdtreadonly = false;
  contreadonly = false;
  deleteButttondisable = false;
  saveButttondisable = false;
  
  /**
   * refsの定義
   */
  get refs(): any {
    return this.$refs;
  }
  
  // 画面ロード
  async created() {
    
    console.log(this.selectList);
    await this.initInfo();
  }
  
  async initInfo(){
    
    // 活性状態制御
    if (this.color === "pink"){
      // 登録ダイアログ
      this.urdFlgdisable = true;
      this.dltnFlgdisable = false;
      this.Noreadonly = true;
      this.titlereadonly = false;
      this.ahdreadonly = false;
      this.cuzreadonly = true;
      this.ymdtreadonly = true;
      this.contreadonly = false;
      this.deleteButttondisable = true;
      this.saveButttondisable = false;
      
      this.messageParam.urdFlg = "1";
    }
    else if(this.color === "light-blue"){
      if(this.tabNum === 0){
        // 表示ダイアログ
        this.urdFlgdisable = false;
        this.dltnFlgdisable = false;
        this.Noreadonly = true;
        this.titlereadonly = true;
        this.ahdreadonly = true;
        this.cuzreadonly = true;
        this.ymdtreadonly = true;
        this.contreadonly = true;
        this.deleteButttondisable = false;
        this.saveButttondisable = false;
      }
      else if(this.tabNum === 1){
        // 表示ダイアログ
        this.urdFlgdisable = true;
        this.dltnFlgdisable = true;
        this.Noreadonly = true;
        this.titlereadonly = true;
        this.ahdreadonly = true;
        this.cuzreadonly = true;
        this.ymdtreadonly = true;
        this.contreadonly = true;
        this.deleteButttondisable = true;
        this.saveButttondisable = true;
      }
    }
    
    console.log(this.messageParam);
  }
  
  /**
   * 入力された値を返す
   */
  get localData(): IHomeParams {

    // 未読サインの制御、true = "1"とみなす
    if (this.messageParam.urdFlg){
      this.messageParam.urdFlg = "1";
    }
    else {
      this.messageParam.urdFlg = null;
    }
    
    // 非表示サインの制御、true = "1"とみなす
    if (this.messageParam.dltnFlg){
      this.messageParam.dltnFlg = "1";
    }
    else {
      this.messageParam.dltnFlg = null;
    }
    
    return this.messageParam;
  }
  
  // 登録イベント
  async clickSave() {
    
    this.$emit("dialog-ok", this.localData);
  }
  
  
  // 削除イベント
  async clickDelete() {
    
    this.$emit("dialog-delete", this.localData, "delete");
  }
  
  // キャンセルイベント
  async clickCancel(): Promise<void> {
    
    if(this.tabNum === 0){
      if (
        !(await this.refs.confirm.open("確認", "未登録の内容変更がある場合、情報が破棄されます。よろしいですか？", {
          width: 500,
        }))
      ) {
        return;
      }
      
    }
      
    this.$emit("dialog-cancel");
  }
    
}

